import React from "react";
import logo from "../../assets/image/logo.png";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="">
        <div className="container">
          <div className="default-padding">
            <div className="row gy-5">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer_about wow animate__animated animate__fadeInLeft">
                  <img src={logo} alt="" />
                  {/* <p className="">
                    We offer a wide range of courses in various subjects, from
                    business and technology to art and personal development
                  </p> */}
                  <p>
                    Lorem ipsum dolor amet consecto adi pisicing elit sed eiusm
                    tempor incidid unt labore dolore.
                  </p>
                  <p>Add:70-80 Upper St Norwich NR2</p>
                  <p>Call:+01 123 5641 231</p>
                  <p>Email:info@oneehr.net</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer_link wow animate__animated animate__fadeInUp">
                  <h4>Online Platform</h4>
                  <ul>
                    <li>
                      {/* <a href="">Home</a> */}
                      <Link to={"#"}>About</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Courses</Link>
                    </li>
                    {/* <li>
                      <a href="">Blog</a>

                    </li> */}
                    <li>
                      <Link to={"#"}>Instructor</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Events</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Instructor Profile</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Purchase Guide</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer_link wow animate__animated animate__fadeInUp">
                  <h4>Links</h4>
                  <ul>
                    <li>
                      <Link to={"#"}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Gallery</Link>
                    </li>
                    <li>
                      <Link to={"#"}>News & Articles</Link>
                    </li>
                    <li>
                      <Link to={"#"}>FAQ's</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Sign in/Registration</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Coming Soon</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer_address wow animate__animated animate__fadeInUp">
                  <h4>Contacts</h4>
                  <ul>
                    <li>
                      <i></i>
                      <p>
                        Enter your email address to register to our newsletter
                        subscription
                      </p>
                    </li>
                    <li>
                      <i></i>
                      <p>info@oneehr.net</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="footer_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow animate__animated animate__fadeInLeft">
              <p className="text-center">
                Copyright 2024 EduBlink Designed By DevsBlink. All Rights
                Reserved
              </p>
            </div>
            {/* <div className="col-md-6 wow animate__animated animate__fadeInRight">
              <ul>
                <li>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={"/terms-conditions"}>Terms & Conditions</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
