import React from "react";

import shape from "../../assets/image/shap.png";
import "./feature.css";
import NewsBox from "../NewsBox/NewsBox";
const Features = () => {
  return (
    <section className="features__area">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-4">
          <div className="col-md-6 text-center">
            <h3 className="sub_heading">Latest Articles</h3>
            <h1 className="main_heading">Get News with EduBlink</h1>
            <img className="heading_shape mt-3" src={shape} alt="" />
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className=" col-lg-4 col-md-6">
            <NewsBox />
          </div>
          <div className=" col-lg-4 col-md-6">
            <NewsBox />
          </div>
          <div className=" col-lg-4 col-md-6">
            <NewsBox />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
