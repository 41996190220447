import AboutUs from "../Pages/AboutUs/AboutUs";
import AllInstructors from "../Pages/AllInstructors/AllInstructors";
import ContactUs from "../Pages/ContactUs/ContactUs";
import CourseDetail from "../Pages/Course/CourseDetail";
import Courses from "../Pages/Course/Courses";
import Home from "../Pages/Home/Home";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import Login from "../Pages/Login/Login";
import Register from "../Pages/Login/Register";
import OurClients from "../Pages/OurClients/OurClients";
import PrivacyPolicy from "../Pages/TermsConditions/PrivacyPolicy";
import TermsConditions from "../Pages/TermsConditions/TermsConditions";

export const routes = [
  {
    notLazy: true,
    path: "/",
    element: Home,
    role: "private",
    name: "Home",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/course",
    element: Courses,
    role: "private",
    name: "Courses",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/course-details/:id",
    element: CourseDetail,
    role: "private",
    name: "CourseDetail",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/about-us",
    element: AboutUs,
    role: "private",
    name: "AboutUs",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/instructors",
    element: AllInstructors,
    role: "private",
    name: "instructors",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/contact-us",
    element: ContactUs,
    role: "private",
    name: "Contact Us",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/terms-conditions",
    element: TermsConditions,
    role: "private",
    name: "TermsConditions",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/privacy-policy",
    element: PrivacyPolicy,
    role: "private",
    name: "PrivacyPolicy",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/register",
    element: Register,
    role: "private",
    name: "Register",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/login",
    element: Login,
    role: "private",
    name: "Login",
    subRouting: false,
  },
  {
    notLazy: true,
    path: "/forgot-password",
    element: ForgotPassword,
    role: "private",
    name: "ForgotPassword",
    subRouting: false,
  },
  // {
  //   notLazy: true,
  //   path: "/our-clients",
  //   element: OurClients,
  //   role: "private",
  //   name: "Our Clients",
  //   subRouting: false,
  // },
  // {
  //   notLazy: true,
  //   path: "/blog",
  //   element: Blog,
  //   role: "private",
  //   name: "Blog",
  //   subRouting: false,
  // },
  // {
  //   notLazy: true,
  //   path: "/blog/:id",
  //   element: Single,
  //   role: "private",
  //   name: "Blog",
  //   subRouting: false,
  // },
];
